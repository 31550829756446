<script setup>
import {onMounted, ref} from 'vue';

defineProps({
    modelValue: String,
    placeholder: String,
});

defineEmits(['update:modelValue', 'blur']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        :placeholder="placeholder"
        class="py-3 px-4 border-zinc-300/90 focus:border-zinc-500 dark:placeholder-zinc-500 placeholder-zinc-400 dark:bg-zinc-100/5 focus:dark:bg-zinc-100/[7.5%] dark:border-zinc-600/30 dark:text-zinc-100 duration-100 ring-0 outline-none focus:ring-transparent dark:focus:ring-transparent rounded-lg"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
    >
</template>
